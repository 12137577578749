exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-connect-index-js": () => import("./../../../src/pages/connect/index.js" /* webpackChunkName: "component---src-pages-connect-index-js" */),
  "component---src-pages-epk-index-js": () => import("./../../../src/pages/epk/index.js" /* webpackChunkName: "component---src-pages-epk-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sounds-index-js": () => import("./../../../src/pages/sounds/index.js" /* webpackChunkName: "component---src-pages-sounds-index-js" */),
  "component---src-pages-tour-index-js": () => import("./../../../src/pages/tour/index.js" /* webpackChunkName: "component---src-pages-tour-index-js" */),
  "component---src-pages-visuals-index-js": () => import("./../../../src/pages/visuals/index.js" /* webpackChunkName: "component---src-pages-visuals-index-js" */)
}

